@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url('fira-sans-300.eot');
  src: url('fira-sans-300.woff') format('woff'), url('fira-sans-300.woff2') format('woff2'), url('fira-sans-300.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: url('fira-sans-300italic.eot');
  src: url('fira-sans-300italic.woff') format('woff'), url('fira-sans-300italic.woff2') format('woff2'), url('fira-sans-300italic.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: url('fira-sans-500.eot');
  src: url('fira-sans-500.woff') format('woff'), url('fira-sans-500.woff2') format('woff2'), url('fira-sans-500.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  src: url('fira-sans-500italic.eot');
  src: url('fira-sans-500italic.woff') format('woff'), url('fira-sans-500italic.woff2') format('woff2'), url('fira-sans-500italic.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fira-sans-400.eot');
  src: url('fira-sans-400.woff') format('woff'), url('fira-sans-400.woff2') format('woff2'), url('fira-sans-400.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url('fira-sans-400italic.eot');
  src: url('fira-sans-400italic.woff') format('woff'), url('fira-sans-400italic.woff2') format('woff2'), url('fira-sans-400italic.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url('fira-sans-600.eot');
  src: url('fira-sans-600.woff') format('woff'), url('fira-sans-600.woff2') format('woff2'), url('fira-sans-600.eot') format('embedded-opentype');
  font-display: block;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  src: url('fira-sans-600italic.eot');
  src: url('fira-sans-600italic.woff') format('woff'), url('fira-sans-600italic.woff2') format('woff2'), url('fira-sans-600italic.eot') format('embedded-opentype');
  font-display: block;
}